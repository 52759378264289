import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Qui suis-je ?`}</h1>
    <p>{` Ancien employé de banque, j’ai passé des années à utiliser des applications web ancrées dans les technologies du passé. Des applications web qui sont souvent conçues en dépit de tout bon sens vis-à-vis de l’utilisateur. C’est un triste constat que je ne suis pas seul à faire. Trop souvent, l’utilisateur doit s’adapter à des techniques poussiéreuses et complexes qui le pénalisent. `}</p>
    <p>{` Je dis non, non, non, NON ! La technologie doit être au service de son utilisateur et non l’inverse !
Passionné de développement web depuis le collège, j’ai décidé de me remonter les manches pour faire en sorte que les utilisateurs profitent le plus tôt possible des dernières innovations.`}</p>
    <p>{` J’ai donc dit adieu à la banque avec un plaisir non dissimulé et je me suis attelé à forger mes compétences. N’ayant pas de diplôme universitaire dans l’informatique (bonjour faculté de droit !), j’ai travaillé sans relâche avec la richesse du web et des ressources disponibles pour façonner avec minutie mon expertise actuelle. `}</p>
    <p>{` Le monde du web bouge très vite et je me forme constamment et au moins une heure par jour pour m’améliorer sans cesse et adopter les techniques les plus modernes pour créer des expériences merveilleuses. `}</p>
    <p>{` J’ai la chance d’être propulsé par deux moteurs d’une puissance défiant toute concurrence (non pas des moteurs Tesla), ma femme et ma fille ! C’est ma source de motivation et de discipline la plus absolue. Si l’on rajoute à ça le fait de pouvoir faire tous les jours ce que j’aime, ça, ça n’a pas de prix. `}</p>
    <p>{` Je ne changerai peut-être pas le monde du web dans son ensemble, mais je compte bien y contribuer. `}</p>
    <p>{` Osez entrer dans le futur, votre business vous dira merci et je ne vous parle même pas de vos clients qui seront aux anges. `}</p>
    <p>{` Nous avons beaucoup de travail, on commence ?`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      