import React from "react"
import Layout from "../components/Layout"
import { css } from "styled-components"
import starsDark from "../images/stars-dark.svg"
import Header from "../components/Header"
import Footer from "../components/Footer"
import BioMDX from "../content/bio.mdx"
import SEO from "../components/seo"

const BioPage = () => {
  return (
    <Layout>
      <SEO title="Biographie" />
      <div
        css={css`
          background-image: url(${starsDark});
          background-size: auto;
          background-repeat: repeat;
        `}
      >
        <Header />
        <div
          css={css`
            max-width: 600px;
            margin: 0 auto;
            text-align: justify;
          `}
        >
          <BioMDX />
        </div>
        <Footer />
      </div>
    </Layout>
  )
}

export default BioPage
